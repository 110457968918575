@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'MarkProRegular';
    src: local('MarkProRegular'), url(./fonts/Mark-Pro-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'MarkProMedium';
  src: local('MarkProMedium'), url(./fonts/Mark-Pro-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'MarkProBold';
  src: local('MarkProBold'), url(./fonts/Mark-Pro-Bold.ttf) format('truetype');
}


body {
    overflow: auto;
    -webkit-tap-highlight-color: transparent;
}